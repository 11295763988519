import {
  CheckboxVisibility,
  DetailsList,
  FontIcon,
  IconButton,
  Link,
  TooltipHost
} from "@fluentui/react";
import { IColumn } from "@fluentui/react";
import { IEstablishment, IImpact, IMeta } from "@solarforschools/sfs-core/dist/school/types";
import { IField } from "../../dynamic-form/DynamicForm"
import { Regions, requiredMessage } from "../common/common"
import { StringMap } from "../common/types";
import { isSortable } from "../common/utils";
import { metaStatusOptions, stages } from "./common";
import { websiteHost } from '../../../store/config';
import { validateSchoolAPi } from "../../../store/client/school";
import moment from "moment";

export const getGeneralFields = (options: StringMap): IField[] => {
  return [
    {
      key: 'name',
      label: 'School name',
      type: 'string',
      className: 'ms-Grid-col ms-lg6',
    }, {
      key: 'slug',
      label: 'Slug',
      type: 'string',
      className: 'ms-Grid-col ms-lg6',
      extras: { required: true, ...options?.school?._id ? { disabled: true } : {} },
    }, {
      key: 'students',
      label: 'Students',
      type: 'number',
      placeholder: 'Enter Students',
      step: "1",
      className: 'ms-Grid-col ms-lg1',
    }, {
      key: 'tel',
      label: 'Tel/Phone',
      type: 'string',
      placeholder: 'Enter Tel',
      className: 'ms-Grid-col ms-lg2',
    }, {
      key: 'website',
      label: 'Website',
      type: 'string',
      placeholder: 'Enter Website',
      className: 'ms-Grid-col ms-lg4',
    }, {
      key: 'rank',
      label: 'Rank',
      type: 'number',
      placeholder: 'Enter Rank',
      step: "1",
      className: 'ms-Grid-col ms-lg1',
    }, {
      key: 'yield',
      label: 'Yield',
      type: 'number',
      placeholder: 'Enter Yield',
      step: "1",
      className: 'ms-Grid-col ms-lg1',
    },
    {
      key: 'country',
      label: 'Region',
      type: 'dropdown',
      options: Regions.filter(r => r.key !== 'all'),
      className: 'ms-Grid-col ms-lg1',
    },
    {
      key: 'consumption',
      label: 'Consumption',
      type: 'number',
      placeholder: 'Enter Consumption',
      step: "1",
      className: 'ms-Grid-col ms-lg1',
    },
    {
      key: 'pricePaid',
      label: 'Price Paid',
      type: 'number',
      placeholder: 'Enter PricePaid',
      step: "0.1",
      className: 'ms-Grid-col ms-lg1',
    },
    {
      key: 'urn',
      label: 'Urn',
      type: 'string',
      placeholder: 'Enter urn',
      className: 'ms-Grid-col ms-lg1',
    }, {
      key: 'live',
      label: 'Live',
      type: 'checkbox',
      className: 'ms-Grid-col ms-lg1 margin-top-lg',
    }, {
      key: 'isPortfolio',
      label: 'isPortfolio',
      type: 'checkbox',
      className: 'ms-Grid-col ms-lg1 margin-top-lg',
    }, {
      key: 'planDate',
      label: 'Plan Date',
      type: 'date',
      placeholder: 'Pick Plan date',
      className: 'ms-Grid-col ms-lg2',
    }, {
      key: 'activeStage.name',
      label: 'Stage',
      type: 'dropdown',
      options: stages,
      className: 'ms-Grid-col ms-lg1',
    }, {
      key: 'activeStage.active',
      label: 'Active',
      type: 'checkbox',
      className: 'ms-Grid-col ms-lg1 margin-top-lg',
    },
  ]
}

export const getMetaFields = (options: StringMap): IField[] => {
  return [
    {
      key: 'meta.status',
      label: 'Status',
      type: 'dropdown',
      options: metaStatusOptions,
      className: 'ms-Grid-col ms-lg2',
    },
    {
      key: 'meta.status2',
      label: 'Status2',
      type: 'dropdown',
      options: options.status?.map((s: string) => ({ key: s, text: s })),
      className: 'ms-Grid-col ms-lg2',
    },
    {
      key: 'meta.isPremium',
      label: 'isPremium',
      type: 'checkbox',
      className: 'ms-Grid-col ms-lg1 margin-top-lg',
    },
    {
      key: 'meta.isRegNotificationEnable',
      ariaLabel: 'When enable, send register notifiaction on team and email also create pipedrive data.',
      label: 'isRegNotificationEnable',
      type: 'checkbox',
      className: 'ms-Grid-col ms-lg2 margin-top-lg',
    },
  ]
}

export const locationFields: IField[] = [
  {
    key: 'address.location.coordinates.0',
    label: 'Lat',
    type: 'number',
    className: 'ms-Grid-col ms-lg2',
    placeholder: 'Enter Lat',
    extras: { readonly: true, disabled: true }
  },
  {
    key: 'address.location.coordinates.1',
    label: 'Lng',
    type: 'number',
    className: 'ms-Grid-col ms-lg2',
    extras: { readonly: true, disabled: true },
    placeholder: 'Enter Lng'
  }
]

export const addressFileds: IField[] = [
  {
    key: 'address.street',
    label: 'Street',
    type: 'string',
    placeholder: 'Enter Street',
    className: 'ms-Grid-col ms-lg6',
  },
  {
    key: 'address.zipcode',
    label: 'Zipcode',
    type: 'string',
    placeholder: 'Enter Zipcode',
    className: 'ms-Grid-col ms-lg2',
  }, {
    key: 'address.city',
    label: 'City',
    type: 'string',
    placeholder: 'Enter City',
    className: 'ms-Grid-col ms-lg3',
  }, {
    key: 'address.county',
    label: 'County',
    type: 'string',
    placeholder: 'Enter County',
    className: 'ms-Grid-col ms-lg3',
  }, {
    key: 'address.state',
    label: 'State',
    type: 'string',
    placeholder: 'Enter State',
    className: 'ms-Grid-col ms-lg3',
  }, {
    key: 'address.country',
    label: 'Country',
    type: 'string',
    placeholder: 'Enter Country',
    className: 'ms-Grid-col ms-lg3',
  }, {
    key: 'address.district',
    label: 'District',
    type: 'string',
    placeholder: 'Enter District',
    className: 'ms-Grid-col ms-lg3',
  }, {
    key: 'address.formatted',
    label: 'Formatted address',
    type: 'string',
    placeholder: 'Enter Formatted',
    className: 'ms-Grid-col ms-lg6',
  }
]

export const liveDataFields: IField[] = [
  {
    key: 'impact.isFeatured',
    label: 'Is Featured',
    type: 'checkbox',
    className: 'ms-Grid-col ms-lg2 margin-top-lg',
  },
  {
    key: 'showAnalysis',
    label: 'ShowAnalysis',
    type: 'checkbox',
    className: 'ms-Grid-col ms-lg2 margin-top-lg',
  },
  {
    key: 'showLive',
    label: 'ShowLive',
    type: 'checkbox',
    className: 'ms-Grid-col ms-lg1 margin-top-lg',
  },
  {
    key: 'impact.installed',
    label: 'Installed',
    type: 'date',
    placeholder: 'Pick Installed date',
    className: 'ms-Grid-col ms-lg2',
    extras: { isRequired: true }
  },
  {
    key: 'impact.peakPower',
    label: 'PeakPower',
    type: 'number',
    step: '0.01',
    placeholder: 'Enter Peak Power',
    className: 'ms-Grid-col ms-lg1',
  },
  {
    key: 'impact.panels',
    label: 'No of Panels',
    type: 'number',
    step: '1',
    placeholder: 'Enter #panel',
    className: 'ms-Grid-col ms-lg1',
  }, {
    key: 'impact.comment',
    label: 'Comment',
    type: 'string',
    placeholder: 'Enter Comment',
    className: 'ms-Grid-col ms-lg2',
  },
]

export const displayDataFields: IField[] = [
  {
    key: 'displaySettings.quote.display',
    label: 'Quote Display',
    type: 'checkbox',
    className: 'ms-Grid-col ms-lg2 margin-top-lg',
  },
  {
    key: 'displaySettings.quote.author',
    label: 'Quote Author',
    type: 'string',
    placeholder: 'Enter Author',
    className: 'ms-Grid-col ms-lg3',
  },
  {
    key: 'displaySettings.quote.quote',
    label: 'Quote',
    type: 'string',
    placeholder: 'Enter Quote',
    className: 'ms-Grid-col ms-lg6',
    extras: { multiline: true, rows: 3 }
  },
]
export interface SchoolTabErrors {
  general: boolean;
  location: boolean;
  live: boolean;
  systems: boolean;
  display: boolean;
}

export const validateSchool = async (school: IEstablishment): Promise<StringMap> => {
  let errors: StringMap = {};
  const { name, country, consumption, students, pricePaid } = school || {}
  if (!name) errors['name'] = requiredMessage;
  if (school._id) {
    if (!pricePaid) errors['pricePaid'] = requiredMessage;
    if (!consumption) errors['consumption'] = requiredMessage;
    if (!students) errors['students'] = requiredMessage;
    if (!school.yield) errors['yield'] = requiredMessage;
  }
  if (!country) errors['country'] = requiredMessage;

  if (country === "GB" && school.urn == "") errors['urn'] = requiredMessage;

  // address validation
  const { street, zipcode, formatted, city, location } = school?.address || {}
  if (!street) errors['address.street'] = requiredMessage;
  if (!zipcode) errors['address.zipcode'] = requiredMessage;
  if (!formatted) errors['address.formatted'] = requiredMessage;
  if (!city) errors['address.city'] = requiredMessage;
  if (!location?.coordinates[0]) errors['address.location.coordinates.0'] = requiredMessage
  if (!location?.coordinates[1]) errors['address.location.coordinates.1'] = requiredMessage

  const { quote, display, author } = school?.displaySettings?.quote || {}
  if (display) {
    if (!quote) errors['displaySettings.quote.quote'] = requiredMessage;
    if (!author) errors['displaySettings.quote.author'] = requiredMessage;
  }

  if (school.live) {
    const { installed, panels, peakPower } = school?.impact || {}
    if (!installed) errors['impact.installed'] = requiredMessage;
    if (!panels) errors['impact.panels'] = requiredMessage;
    if (!peakPower) errors['impact.peakPower'] = requiredMessage;
  }

  // meta validation
  //if (!Object(metaStatusOptions).keys().includes(school.meta.status)) errors['meta.status'] = requiredMessage;
  if (!Object.keys(errors).length) {
    errors = await validateSchoolAPi(school) as unknown as StringMap
  }
  return errors;
}

export const getTabErrors = (errors: StringMap, school: IEstablishment): SchoolTabErrors => {
  const tabErrors = { ...schoolTabs }
  Object.keys(errors).forEach(property => {
    if (getGeneralFields({ school }).find(field => field.key === property)) {
      tabErrors.general = true;
    }
    if (getMetaFields({ school }).find(field => field.key === property)) {
      tabErrors.general = true;
    }
    if (locationFields.find(field => field.key === property)) {
      tabErrors.location = true;
    }
    if (addressFileds.find(field => field.key === property)) {
      tabErrors.location = true;
    }
    if (liveDataFields.find(field => field.key === property)) {
      tabErrors.live = true;
    }

    if (displayDataFields.find(field => field.key === property)) {
      tabErrors.display = true
    }
  })
  return tabErrors;
}

export const schoolTabs: SchoolTabErrors = {
  general: false,
  location: false,
  live: false,
  systems: false,
  display: false
}

export const getSchoolColumns = (params: {
  actions: {
    icon: string,
    onClick: (school: IEstablishment) => any,
    title?: string,
    ariaLabel?: string
  }[]
}): IColumn[] => {
  return [
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      minWidth: 150,
      data: "string",
      ...isSortable,
      onRender: (item) => {
        return (
          <TooltipHost content={item.name} closeDelay={500}>
            <Link rel='noopener noreferrer' target='_blank' href={`${websiteHost}/schools/${item.slug}`}>
              {item.name}
            </Link>
          </TooltipHost>
        );
      },
    },
    {
      key: "planDate",
      name: "Plan Date",
      fieldName: "planDate",
      minWidth: 80,
      maxWidth: 80,
      data: "string",
      ...isSortable,
      onRender: (item) => item.planDate ? moment(item.planDate).format("YYYY/MM/DD") : '',
    },
    {
      key: "activeStage.name",
      name: "Stage",
      fieldName: "activeStage.name",
      minWidth: 70,
      data: "string",
      ...isSortable,
      onRender: (item: IEstablishment) => {
        return (
          <TooltipHost content={item.activeStage?.name} closeDelay={500}>
            <span style={{ color: item.activeStage?.active ? 'green' : 'red' }}>{item.activeStage?.name}</span>
          </TooltipHost>
        );
      },
    },
    {
      key: "address.city",
      name: "City",
      fieldName: "address.city",
      minWidth: 70,
      data: "string",
      ...isSortable,
      onRender: (item) => {
        return (
          <TooltipHost content={item.address?.city} closeDelay={500}>
            {item.address?.city}
          </TooltipHost>
        );
      },
    },
    {
      key: "address.zipcode",
      name: "Zip Code",
      fieldName: "address.zipcode",
      minWidth: 70,
      ...isSortable,
      onRender: (item) => item.address?.zipcode
    },
    {
      key: "address.street",
      name: "Street",
      fieldName: "address.street",
      minWidth: 125,
      ...isSortable,
      onRender: (item) => {
        return (
          <TooltipHost content={item.address?.street} closeDelay={500}>
            {item.address?.street}
          </TooltipHost>
        );
      },
    },
    {
      key: "students",
      name: "Students",
      fieldName: "students",
      minWidth: 90,
      data: "number",
      className: "text-right",
      ...isSortable,
    },
    {
      key: "meta.size",
      name: "Size",
      fieldName: "meta.size",
      minWidth: 90,
      ...isSortable,
      onRender: (item) => {
        return (
          <TooltipHost content={item.meta?.size} closeDelay={500}>
            {item.meta?.size}
          </TooltipHost>
        );
      },
    },
    {
      key: "rank",
      name: "Priority",
      fieldName: "rank",
      minWidth: 60,
      ...isSortable,
    },
    {
      key: "action",
      name: "Action",
      fieldName: "-",
      minWidth: 100,
      onRender: (school: IEstablishment) => {
        return (
          <div className="action-icons-container">
            {params.actions.map((action) => {
              return <IconButton
                title={action.title || ''}
                ariaLabel={action.ariaLabel || ''}
                iconProps={{ iconName: action.icon }}
                onClick={() => action.onClick(school)}
              />
            })}
          </div>
        )
      },
    },
  ];
}

export const initSchool: IEstablishment = {
  address: {
    zipcode: "",
    street: "",
    city: "",
    formatted: "",
    location: null,
    locationType: "",
    country: "",
    district: ""
  },
  capacity: 0,
  comment: "",
  consumption: 0,
  country: 'GB',
  displaySettings: {
    caseStudy: {
      display: false,
      fileUrl: null,
    },
    champions: {
      users: [],
      display: false
    },
    isFeaturedSchool: false,
    quote: {
      display: false,
      quote: "",
      author: undefined,
      image: undefined,
    }
  },
  fax: undefined,
  fundingModel: undefined,
  impact: {} as IImpact,
  irradiation: 0,
  live: false,
  meta: { status: 'Open' } as IMeta,
  name: '',
  pricePaid: 0,
  pupils: 0,
  rank: 0,
  slug: "",
  students: 0,
  tel: "",
  urn: "",
  yield: 0,
  website: "",
}


export const metaData = [
  {
    key: 'community',
    label: 'Community',
    placeholder: 'Enter Community',
    className: 'ms-Grid-col ms-lg2',
  }, {
    key: 'type',
    label: 'Type',
    placeholder: 'Enter Type',
    className: 'ms-Grid-col ms-lg2',
  }, {
    key: 'group',
    label: 'Group',
    placeholder: 'Enter Group',
    className: 'ms-Grid-col ms-lg2',
  }, {
    key: 'authority',
    label: 'Authority',
    placeholder: 'Enter Authority',
    className: 'ms-Grid-col ms-lg2',
  }, {
    key: 'diocese',
    label: 'Diocese',
    placeholder: 'Enter Diocese',
    className: 'ms-Grid-col ms-lg2',
  }, {
    key: 'trust',
    label: 'Trust',
    placeholder: 'Enter Trust',
    className: 'ms-Grid-col ms-lg2',
  }, {
    key: 'religiousCharacter',
    label: 'Religious Character',
    placeholder: 'Enter Religious Character',
    className: 'ms-Grid-col ms-lg2',
  }, {
    key: 'trustSchoolFlag',
    label: 'Trust School Flag',
    placeholder: 'Enter Trust School Flag',
    className: 'ms-Grid-col ms-lg2',
  }, {
    key: 'parliamentaryConstituency',
    label: 'Parliamentary Constituency',
    placeholder: 'Enter parliamentaryConstituency',
    className: 'ms-Grid-col ms-lg2',
  }, {
    key: 'schoolSponsors',
    label: 'School Sponsors',
    placeholder: 'Enter School Sponsors',
    className: 'ms-Grid-col ms-lg2',
  }, {
    key: 'owner',
    label: 'Owner',
    placeholder: 'Enter Owner',
    className: 'ms-Grid-col ms-lg2',
  },
  {
    key: 'FSM',
    label: 'FSM %',
    placeholder: 'Enter FSM',
    className: 'ms-Grid-col ms-lg2',
  }
]

export const markerColorBasedOnStages = {
  Prospect: "red",
  Sales: "blue",
  Build: "green",
  Funded: "green",
  Managed: "green",
  Developed: "green",
  Operate: "green",
  Develop: "yellow",
  Closed: "orange"
}

export const legendContent = {
  Prospect: "red",
  Sales: "blue",
  "Build, Operate, Funded, Managed, Developed": "green",
  "Develop-Active": "yellow",
  "Inactive and Others": "orange"
}

export const irelandCoords = [
  { lat: 55.251518, lng: -5.916876 },
  { lat: 54.664102, lng: -5.354185 },
  { lat: 54.367166, lng: -5.047262 },
  { lat: 54.466385, lng: -4.365211 },
  { lat: 54.327856, lng: -3.734314 },
  
  { lat: 53.997965, lng: -3.505340 }, // check (mid land mark isle of mark)

  { lat: 53.422727, lng: -5.490595 },
  { lat: 52.175400, lng: -6.036235 },
  { lat: 51.448029, lng: -7.924663 },
  { lat: 51.020977, lng: -9.970815 },
  { lat: 52.380437, lng: -13.422295 },
  { lat: 53.551244, lng: -13.107040 },

  { lat: 55.183538, lng: -9.793475 },
  { lat: 55.700138, lng: -7.936970 },
  { lat: 55.502246, lng: -6.746004 },
  { lat: 55.343212, lng: -6.150522 },
  { lat: 55.251518, lng: -5.916876 }
];
